import React, { Component } from 'react'
import { Link, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Toggle } from 'react-powerplug'
import _ from 'lodash'
import { TiArrowShuffle, TiArrowLeft, TiArrowRight } from 'react-icons/ti'

import SEO from '../components/SEO'
import Disqus from '../components/Disqus'
import Mailchimp from '../components/Mailchimp'
import ShareBar from '../components/ShareBar'
import styled from 'styled-components'
import Layout from '../layouts'

import {
  PostTitle,
  PostText,
  Title,
  PostImage,
  BaseButton,
  Divider,
  StyledLink,
} from '../components/Common.js'

// afterSubmit :: () => (err, data)
export const CTASubscribe = ({ title, style, afterSubmit, bottomLine }) => (
  <div
    id="tips"
    css={{
      margin: '0px auto 60px',
      maxWidth: '500px',
    }}
  >
    <Divider />
    <Subscribe style={style}>
      <Title css={{ color: '#78abbe', fontSize: '3rem', lineHeight: '1.5em' }}>
        {title || 'Want help freelancing?'}
      </Title>
      <SubscribeText>
        Tired of getting generic advice that seems like it's out of a dated
        textbook?
      </SubscribeText>
      <SubscribeText>
        Sign up and get the advice other freelancers are not willing to share.
      </SubscribeText>
      {/* <SubscribeText>
        Freelancing is easy when someone gives you all the answers and remove
        all the guess work. I plan to give all the answers to freelancing in
        super easy to understand bit sized tips.
      </SubscribeText> */}

      <Mailchimp afterSubmit={afterSubmit} />

      <SubscribeText
        css={{
          fontSize: '1.1rem',
          fontStyle: 'italic',
        }}
      >
        <b>Note:</b> You may get a single email one week and 4 the next.
        Consider it a surprise in your inbox!
      </SubscribeText>
    </Subscribe>

    {bottomLine && <Divider />}
  </div>
)

const SubscriberOnly = () => {
  return (
    <p
      css={{
        fontSize: '14px',
        border: '1px dashed #ccc',
        padding: '10px',
      }}
    >
      This article was selected from the mailing list. Only some advice makes it
      to the website, if you're interested in getting subscriber-only content,
      be sure to <Link to="/contact">subscribe here</Link>.
    </p>
  )
}

const CommentsToggle = props => {
  const { post } = props

  return (
    <div style={{ marginTop: 40 }}>
      <Toggle initial={false}>
        {({ on, toggle }) => (
          <>
            <div style={{ textAlign: 'center' }}>
              {!on && (
                <BaseButton
                  onClick={toggle}
                  checked={on}
                  style={{
                    padding: '13px 40px',
                    background: '#ea9629',
                  }}
                >
                  Show comments
                </BaseButton>
              )}
            </div>
            {on && <Disqus postNode={post} />}
          </>
        )}
      </Toggle>
    </div>
  )
}

const fixLegacyLinks = content =>
  content
    .replace('http://69.195.124.79/~thugahol/freelanceafterfive/', '/')
    .replace('http://box879.temp.domains/~thugahol/freelanceafterfive/', '/')

const SuperVortex = props => {
  const { slugList, currentSlug } = props

  const postList = slugList.map(o => o.node.slug)

  const currentPostIndex = _.findIndex(postList, o => o === currentSlug)
  const nextPostIndex = currentPostIndex + 1
  const prevPostIndex = currentPostIndex - 1

  const nextPost = postList[nextPostIndex]
  const prevPost = postList[prevPostIndex]

  // need to omit current, prev and next
  const omittedPosts = postList
    .filter(o => o !== currentSlug) //killing current
    .filter(o => o !== nextPost)
    .filter(o => o !== prevPost)

  const randomPost = _.sample(omittedPosts)

  const labelCss = { margin: 0 }
  const linkCss = { textAlign: 'center' }

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        fontWeight: '700',
        fontSize: '21px',
        marginTop: '80px',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '300px',
      }}
    >
      {prevPost && (
        <StyledLink to={prevPost} css={linkCss}>
          <p css={labelCss}>Previous</p> <TiArrowLeft size={25} />
        </StyledLink>
      )}

      <StyledLink to={randomPost} css={linkCss}>
        <p css={labelCss}>Random</p> <TiArrowShuffle size={25} />
      </StyledLink>

      {nextPost && (
        <StyledLink to={nextPost} css={linkCss}>
          <p css={labelCss}>Next</p> <TiArrowRight size={25} />
        </StyledLink>
      )}
    </div>
  )
}
class PostTemplate extends Component {
  render() {
    const post = this.props.data.wordpressPost

    //we need to manually create the url for the sharing bar
    //TODO this can be abstracted out with the sharebar
    const url = encodeURIComponent(
      `https://freelanceafterfive.com/${post.slug}`
    )

    // we need to make sure to replace the bs from wordpress
    //so we can link to gatsby pages opposed to back to the headless wordpress posts
    const content = fixLegacyLinks(post.content)

    // TODO i should be able to use the _.get for these and not have any issues
    const featuredImage =
      post.featured_media &&
      post.featured_media.localFile.childImageSharp.original.src

    return (
      <Layout title={post.title}>
        {/* TODO get this SEO plugin working */}
        {/* https://www.gatsbyjs.org/docs/add-seo-component/ */}
        <SEO postPath={post.slug} postNode={post} postSEO />

        {featuredImage ? (
          <PostImage
            style={{
              marginBottom: 40,
              background: `url(${featuredImage})`,
              backgroundSize: 'cover',
            }}
          />
        ) : (
          <div css={{ height: '40px' }} />
        )}

        <SubscriberOnly />

        <PostTitle
          style={{ color: '#78abbe', marginBottom: 30 }}
          dangerouslySetInnerHTML={{ __html: post.title }}
        />

        <PostText
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />

        <SuperVortex
          slugList={this.props.data.allWordpressPost.edges}
          currentSlug={post.slug}
        />

        <ShareBar url={url} post={post} />

        <CTASubscribe />
        <Divider />

        <CommentsToggle post={post} />
      </Layout>
    )
  }
}

const Subscribe = styled.div`
  text-align: center;
`

const SubscribeText = styled.p`
  color: #5d5c5c;
`

export const pageQuery = graphql`
  query currentPostQuery($id: String!) {
    wordpressPost(id: { eq: $id }) {
      id
      slug
      title
      content
      excerpt
      modified
      featured_media {
        id
        source_url
        localFile {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
      better_featured_image {
        media_details {
          sizes {
            large {
              source_url
            }
          }
        }
      }
      ...PostIcons
    }
    site {
      siteMetadata {
        title
        subtitle
      }
    }

    allWordpressPost(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          id
          title
          excerpt
          slug
          date(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default PostTemplate
