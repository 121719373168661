//@flow
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import React, { Component } from 'react'
import Link from 'gatsby-link'
import Media from 'react-media'
import styled from 'styled-components'

import {
  StyledLink,
  PostBlock,
  PostTitle,
  PostText,
  PostReadMore,
  BigQuote,
} from '../components/Common.js'
import Layout from '../layouts'
import SEO from '../components/SEO.js'
import { CTASubscribe } from '../templates/post'

import config from '../../config'

const renderTagline = (
  <BigQuote>"freelancing is easy when you're given all the answers"</BigQuote>
)

const PostSummary = props => {
  const { postHtml, toLink } = props

  // NOTE adding the margin bottom since the HTML
  // from the post is too big for the summary
  return (
    <div data-testid="post-summary">
      <PostText
        dangerouslySetInnerHTML={{
          __html: postHtml.replace('<p>', '<p style="margin-bottom: 5px;">'),
        }}
      />

      <PostReadMore to={toLink} children="Read More" />
    </div>
  )
}

const PostList = props => {
  const { edges, stopAt = 0, startAt = 0, small } = props

  let list = edges

  if (stopAt) {
    list = edges.slice(0, stopAt)
  } else if (startAt) {
    list = edges.slice(startAt)
  }

  return list.map(({ node }, index) => {
    const hasImage =
    node.featured_media && node.featured_media.localFile.childImageSharp[small ? 'small' : 'fluid']

    const imageStyle = {
      width: small ? 250 : '100%',
      marginRight: small && 20,
    }

    const wrapperStyle = {
      display: small ? 'flex' : 'block',
    }

    const renderPostImage = hasImage && (
      <Link to={node.slug}>
        <Img fluid={hasImage} style={imageStyle} data-testid="post-image" />
      </Link>
    )

    return (
      <PostBlock key={node.slug} data-testid="post-block">
        <StyledLink to={node.slug}>
          <PostTitle
            dangerouslySetInnerHTML={{
              __html: node.title,
            }}
          />
        </StyledLink>

        {/* NOTE not all of the posts will have images moving forward */}

        {/* NOTE works fine on dev but not for prod */}
        {/* <div style={wrapperStyle}>
          {renderPostImage}

          <PostSummary postHtml={node.excerpt} toLink={node.slug} />
        </div> */}

        {/* NOTE the bottom works fine, but the top post break with no image */}
        <Wrap>
          {renderPostImage}

          <PostSummary postHtml={node.excerpt} toLink={node.slug} />
        </Wrap>
      </PostBlock>
    )
  })
}

// TODO for some damn reason this isnt working as expected so i needed to dup
// the comp and use the settings different for each side
// i can come back and abstract shit later... sick of this shit right now
// but its working fine
const PostListFeatured = props => {
  const { edges, stopAt = 0, startAt = 0, small } = props

  let list = edges

  if (stopAt) {
    list = edges.slice(0, stopAt)
  } else if (startAt) {
    list = edges.slice(startAt)
  }

  return list.map(({ node }, index) => {
    const hasImage =
      node.featured_media &&
      node.featured_media.localFile.childImageSharp[small ? 'small' : 'fluid']

    const imageStyle = {
      width: small ? 250 : '100%',
      marginRight: small && 20,
    }

    const wrapperStyle = {
      display: small ? 'flex' : 'block',
    }

    const noImageText = !hasImage
      ? {
          border: '15px solid',
          fontSize: '4rem',
          padding: '20px',
        }
      : {}

    const renderPostImage = hasImage && (
      <Link to={node.slug}>
        <Img fluid={hasImage} style={imageStyle} data-testid="post-image" />
      </Link>
    )

    return (
      <PostBlock key={node.slug} data-testid="post-block">
        <StyledLink to={node.slug}>
          <PostTitle
            bigText={!hasImage}
            dangerouslySetInnerHTML={{
              __html: node.title,
            }}
          />
        </StyledLink>

        {/* NOTE not all of the posts will have images moving forward */}

        {/* NOTE works fine on dev but not for prod */}
        <div style={wrapperStyle}>
          {renderPostImage}

          <PostSummary postHtml={node.excerpt} toLink={node.slug} />
        </div>

        {/* NOTE the bottom works fine, but the top post break with no image */}
        {/* <Wrap>
          {renderPostImage}

          <PostSummary postHtml={node.excerpt} toLink={node.slug} />
        </Wrap> */}
      </PostBlock>
    )
  })
}

const Wrap = styled.div`
  display: flex;

  @media (max-width: 500px) {
    display: block;

    .gatsby-image-wrapper {
      width: 100% !important;
    }
  }
`

export default class Home extends Component {
  render() {
    const { data = {} } = this.props

    return (
      <Layout title={config.siteTitle}>
        {renderTagline}

        <PostListFeatured stopAt={4} edges={data.allWordpressPost.edges} />

        <CTASubscribe bottomLine style={{ margin: '0px 0px 60px 0px' }} />

        {/* NOTE when we have mobile, we want to revert back the the feautres 
          render since it works on mobile better */}
        {/* <Media query="(max-width: 470px)">
          {matches => {
            return (
              <PostList
                startAt={4}
                edges={data.allWordpressPost.edges}
                small={!matches}
              />
            )
          }}
        </Media> */}

        <PostList startAt={4} edges={data.allWordpressPost.edges} small />

        <CTASubscribe style={{ margin: '0px 0px 60px 0px' }} />

        <SEO />
      </Layout>
    )
  }
}
// Set here the ID of the home page.
export const pageQuery = graphql`
  query homePageQuery {
    allWordpressPost(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          featured_media {
            localFile {
              childImageSharp {
                original {
                  src
                }
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fluid(maxWidth: 595) {
                  ...GatsbyImageSharpFluid
                }
                small: fluid(maxWidth: 343, maxHeight: 172) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          id
          title
          excerpt
          slug
          date(formatString: "MMMM DD, YYYY")
          better_featured_image {
            media_details {
              sizes {
                large {
                  source_url
                }
              }
            }
          }
        }
      }
    }
  }
`
