import React from 'react'
import styled from 'styled-components'
import {
  FaTwitter,
  FaFacebook,
  FaReddit,
  FaLinkedin,
  FaEnvelope,
} from 'react-icons/fa'

const ICON_SIZE = 40

const labelStyle = {
  display: 'block',
}

export default ({ url, post }) => (
  <>
    <ShareTitle>Sharing is caring :)</ShareTitle>

    <ShareButtons>
      <ShareButton
        target="__blank"
        color="#3b5998"
        href={`https://facebook.com/sharer/sharer.php?u=${url}`}
        style={{
          borderTopLeftRadius: 3,
          borderBottomLeftRadius: 3,
        }}
      >
        <FaFacebook size={ICON_SIZE} />
        {/* <span style={labelStyle}>Facebook</span> */}
      </ShareButton>

      <ShareButton
        target="__blank"
        color="#55acee"
        href={`https://twitter.com/intent/tweet/?text=${encodeURIComponent(
          post.title
        )}&amp;url=${url}`}
      >
        <FaTwitter size={ICON_SIZE} />
        {/* <span style={labelStyle}>Twitter</span> */}
      </ShareButton>

      <ShareButton
        target="__blank"
        color="#ec835d"
        href={`https://reddit.com/submit/?url=${url}`}
      >
        <FaReddit size={ICON_SIZE} />
        {/* <span style={labelStyle}>Reddit</span> */}
      </ShareButton>

      <ShareButton
        target="__blank"
        color="#046293"
        href={`https://www.linkedin.com/shareArticle?mini=true&amp;url=${url}&amp;title=${encodeURIComponent(
          post.title
        )}&amp;summary=${encodeURIComponent(post.title)}&amp;source=${url}`}
      >
        <FaLinkedin size={ICON_SIZE} />
        {/* <span style={labelStyle}>Linkedin</span> */}
      </ShareButton>

      <ShareButton
        color="#78aabf"
        href={`mailto:?subject=${encodeURIComponent(
          post.title
        )}&amp;body=${url}`}
        target="_blank"
        style={{
          borderTopRightRadius: 3,
          borderBottomRightRadius: 3,
        }}
      >
        <FaEnvelope size={ICON_SIZE} />
        {/* <span style={labelStyle}>Email</span> */}
      </ShareButton>
    </ShareButtons>
  </>
)

export const ShareButtons = styled.div`
  justify-content: center;
  display: flex;
`

export const ShareButton = styled.a`
  padding: 8px 8px;
  display: inline-block;
  /* background: ${p => p.color || '#ccc'} !important; */
  color: ${p => p.color || '#ccc'} !important;
  flex: 1;
  border-radius: 3px;
  /* color: white !important; */
  text-decoration: none !important;
  font-size: 12px;
  max-width: 70px;
  text-align: center;

  margin-right: 5px;
  margin-left: 5px;

  &:hover {
    opacity: 0.9;
    background: #f7f7f7;
  }

  &:active {
    opacity: 0.5;
  }

  @media (max-width: 700px) {
    margin-bottom: 10px;
    margin-right: 0px;
    margin-left: 0px;
    border-radius: 0;
  }
`

export const ShareTitle = styled.div`
  margin-bottom: 8px;
  margin-top: 80px;
  text-align: center;
  font-style: italic;
  font-size: 14px;
  font-weight: 500;
`

export const SubscribeButton = styled.button`
  padding: 1px;
  flex: 1;
  background: #78aabf;
  border: 0 !important;
  color: white !important;
  outline: none;

  &:hover {
    opacity: 0.9;
  }
  &:active {
    opacity: 0.7;
  }
`
