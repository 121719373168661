import React, { Component } from 'react'
// import ReactDisqusComments from 'react-disqus-comments'
import config from '../../config'

const disqusShortname = 'freelanceafterfive'

export default class Disqus extends Component {
    state = {
        toasts: [],
    }

    componentDidMount() {
        const { postNode } = this.props

        const url = config.siteUrl + config.pathPrefix + postNode.slug

        var disqus_config = function() {
            this.page.url = url // Replace PAGE_URL with your page's canonical URL variable
            this.page.identifier = postNode.slug // Replace PAGE_IDENTIFIER with your page's unique identifier variable
            this.callbacks.onNewComment = [
                function(comment) {
                    console.log(comment.id)
                    console.log(comment.text)
                },
            ]
        }
        ;(function() {
            // DON'T EDIT BELOW THIS LINE
            var d = document,
                s = d.createElement('script')
            s.src = 'https://freelanceafterfive.disqus.com/embed.js'
            s.setAttribute('data-timestamp', +new Date())
            ;(d.head || d.body).appendChild(s)
        })()
    }

    onSnackbarDismiss() {
        const [, ...toasts] = this.state.toasts
        this.setState({ toasts })
    }
    notifyAboutComment() {
        const toasts = this.state.toasts.slice()
        toasts.push({ text: 'New comment available!' })
        this.setState({ toasts })
    }
    render() {
        const { postNode } = this.props

        const url = config.siteUrl + config.pathPrefix + postNode.slug

        return <div style={{ marginTop: 50 }} id="disqus_thread" />
    }
}
